import type { RouteRecordRaw } from "vue-router";

import { EducationSteps, ProcessRoutes } from "@/router/process.enum";
import { useOrderStore } from "@/store/process/steps/order";
import { usePersonalDataStore } from "@/store/process/steps/personalData";

const routes: RouteRecordRaw[] = [
  {
    path: "/process",
    name: ProcessRoutes.ROOT,
    component: () => import("@/pages/process/Process.vue"),
    redirect: {
      name: ProcessRoutes.CLIENT_TYPE,
    },
    children: [
      {
        path: "client-type",
        name: ProcessRoutes.CLIENT_TYPE,
        component: () => import("@/pages/process/steps/ClientType.vue"),
        meta: {
          isPublic: true,
        },
      },
      {
        path: "personal-data",
        name: ProcessRoutes.PERSONAL_DATA,
        component: () => import("@/pages/process/steps/personalData/PersonalData.vue"),
        meta: {
          isPublic: true,
        },
      },
      {
        path: "education/question",
        name: ProcessRoutes.EDUCATION_QUESTION,
        component: () => import("@/pages/process/steps/personalData/Education.vue"),
        props: {
          step: EducationSteps.QUESTION,
        },
        meta: {
          isPublic: true,
        },
        beforeEnter: (_to, _from, next) => {
          const personalDataStore = usePersonalDataStore();
          if (!personalDataStore.isEligibleForEducationTerms) {
            return next({ name: ProcessRoutes.EXTENDED_PERSONAL_DATA });
          }
          return next();
        },
      },
      {
        path: "extended-personal-data",
        name: ProcessRoutes.EXTENDED_PERSONAL_DATA,
        component: () => import("@/pages/process/steps/ExtendedPersonalData.vue"),
        meta: {
          isPublic: true,
        },
      },
      {
        path: "education/proof",
        name: ProcessRoutes.EDUCATION_PROOF,
        component: () => import("@/pages/process/steps/personalData/Education.vue"),
        props: {
          step: EducationSteps.PROOF,
        },
        meta: {
          isPublic: true,
        },
        beforeEnter: (_to, _from, next) => {
          const personalDataStore = usePersonalDataStore();
          if (!personalDataStore.isEligibleForEducationTerms || !personalDataStore.selectedEducationTerms) {
            return next({ name: ProcessRoutes.IMPORTANT_QUESTIONS, replace: true });
          }
          return next();
        },
      },
      {
        path: "address-data",
        name: ProcessRoutes.ADDRESS_DATA,
        component: () => import("@/pages/process/steps/addressData/AddressData.vue"),
        meta: {
          isPublic: true,
        },
      },
      {
        path: "important-questions/:currentUserIndex/:currentQuestionIndex",
        name: ProcessRoutes.IMPORTANT_QUESTIONS,
        component: () => import("@/pages/process/steps/ImportantQuestions.vue"),
        meta: {
          isPublic: true,
        },
        props: (route) => {
          return {
            currentUserIndex: parseInt(route.params.currentUserIndex as string),
            currentQuestionIndex: parseInt(route.params.currentQuestionIndex as string),
          };
        },
      },
      {
        path: "lead-client",
        name: ProcessRoutes.LEAD_CLIENT_QUESTION,
        component: () => import("@/pages/process/steps/addressData/LeadClientQuestion.vue"),
        meta: {
          isPublic: true,
        },
      },
      {
        path: "benefits",
        name: ProcessRoutes.BENEFIT_ASSIGNMENT,
        component: () => import("@/pages/process/steps/BenefitAssignment.vue"),
        beforeEnter: (_to, _from, next) => {
          const orderStore = useOrderStore();
          if (orderStore.tryAutomaticAssignment()) {
            return next({ name: ProcessRoutes.CONFIRMATION, replace: true });
          }
          return next();
        },
        meta: {
          isPublic: true,
        },
      },
      {
        path: "confirmation",
        name: ProcessRoutes.CONFIRMATION,
        component: () => import("@/pages/process/steps/Confirmation.vue"),
        meta: {
          isPublic: true,
        },
      },
      {
        path: "identification-entry",
        name: ProcessRoutes.IDENTIFICATION_ENTRY,
        component: () => import("@/pages/process/steps/IdentificationEntry.vue"),
        meta: {
          isPublic: true,
        },
      },
      {
        path: "identification/:processId",
        name: ProcessRoutes.IDENTIFICATION,
        component: () => import("@/pages/process/steps/Identification.vue"),
        meta: {
          isPublic: true,
        },
        props: true,
      },
    ],
  },
];

export default routes;
