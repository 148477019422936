import { captureException, captureMessage } from "@sentry/vue";
import type { RouteRecordRaw } from "vue-router";

import { SuitabilityRoutes } from "@/router/suitability.enum";
import { useProcessStore } from "@/store/process";

import { RootRoutes } from "./index.enum";
import type { OrderPayload } from "./order";
import { setOrdersFromJSON } from "./order";

const orderExitRoute = {
  name: RootRoutes.EXIT,
  params: {
    reason: "order",
  },
};

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: SuitabilityRoutes.DOMICILE,
    component: () => import("@/pages/suitability/Domicile.vue"),
    meta: {
      isPublic: true,
    },
    beforeEnter(to, _, next) {
      useProcessStore().reset();

      const orderParam = to.query.order;
      if (!orderParam) {
        const msg = "Failed to initialize order: `order` URL parameter not set";
        console.error(msg);
        captureMessage(msg, "warning");
        return next(orderExitRoute);
      }
      if (Array.isArray(orderParam)) {
        const msg = "Failed to initialize order: `order` URL parameter must not be an array";
        console.error(msg);
        captureException(msg);
        return next(orderExitRoute);
      }
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const decodedParam: OrderPayload = JSON.parse(atob(orderParam));
        setOrdersFromJSON(decodedParam);
      } catch (err) {
        captureException(err);
        console.error("Failed to initialize order: parse/decode error", err);
        return next(orderExitRoute);
      }

      return next();
    },
  },
  {
    path: "/terms",
    name: SuitabilityRoutes.TERMS,
    component: () => import("@/pages/suitability/Terms.vue"),
    meta: {
      isPublic: true,
    },
  },
];

export default routes;
