import { useOrderStore } from "@/store/process/steps/order";

export type OrderPayload = {
  name: string;
  sku: string;
  fixedBenefits: { name: string; sku: string }[];
  selectedOptionalBenefits: { name: string; sku: string }[];
}[];

export function setOrdersFromJSON(order: OrderPayload) {
  const orderStore = useOrderStore();

  let idCounter = 0;
  const newId = () => {
    idCounter += 1;
    return idCounter;
  };

  orderStore.orders = order.map((p) => ({
    id: newId(),
    sku: p.sku,
    name: p.name,
    benefits: [
      ...p.fixedBenefits.map((b) => ({
        id: newId(),
        sku: b.sku,
        name: b.name,
        mandatory: true,
      })),
      ...p.selectedOptionalBenefits.map((b) => ({
        id: newId(),
        sku: b.sku,
        name: b.name,
        mandatory: false,
      })),
    ],
  }));
}
