<template>
  <div
    :class="[
      $style.baseSnack,
      {
        [$style.error]: type === 'ERROR',
        [$style.warning]: type === 'WARNING',
        [$style.success]: type === 'SUCCESS',
      },
    ]"
  >
    <div :class="$style.header">
      <div>
        <SvgIcon :class="$style.icon" :icon="getIcon" size="32px" />
      </div>
      <div>
        <SvgIcon :class="$style.close" icon="navigation/nav-close" size="24px" @click="close" />
      </div>
    </div>
    <div :class="$style.content">
      <div v-if="message">{{ message }}</div>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useSnack } from "vue-haystack";

import type { SnackType } from "@/components/snacks/snackType";
import SvgIcon from "@/components/SvgIcon.vue";

const props = withDefaults(
  defineProps<{
    message?: string;
    type?: SnackType;
  }>(),
  {
    message: undefined,
    type: undefined,
  },
);
const { close } = useSnack();

const getIcon = computed(() => {
  switch (props.type) {
    case "SUCCESS":
      return "options/check-circle";
    case "ERROR":
      return "navigation/error";
    case "WARNING":
      return "navigation/warning";
    default:
      return "navigation/info-circle";
  }
});
</script>

<style lang="scss" module>
.baseSnack {
  @extend %tabletext-one;
  position: relative;
  width: 100vw;
  max-width: 320px;
  background-color: $white;
  box-sizing: border-box;
  border-left: solid 2px $black-three;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    box-shadow: 0 0 30px rgba($black, 0.3);
  }
}
.header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 16px;

  & > div {
    &:last-of-type {
      text-align: right;
    }
  }
}
.content {
  padding: 0 48px 24px 16px;
}

.icon {
  fill: $black-two;
}
.close {
  cursor: pointer;
  &:hover {
    fill: $red-one;
  }
}

.error {
  border-left-color: $red-error;
  .icon {
    fill: $red-error;
  }
}
.success {
  border-left-color: $green-confirm;
  .icon {
    fill: $green-confirm;
  }
}
.warning {
  border-left-color: $orange-warning;
  .icon {
    fill: $orange-warning;
  }
}
</style>
