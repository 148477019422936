import { defineStore } from "pinia";
import { ref } from "vue";

export const useConfirmationStore = defineStore(
  "confirmation",
  () => {
    const finished = ref(false);

    const $reset = () => {
      finished.value = false;
    };

    const setFinished = () => {
      finished.value = true;
    };

    return {
      $reset,
      finished,
      setFinished,
    };
  },
  {
    persist: true,
  },
);
