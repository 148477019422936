import moment from "moment";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { validateMaxAge, validateMinAge } from "@/core/validation";
import { calculateDateOffsetInYears } from "@/helpers/time";
import { assertIsDefined } from "@/helpers/types";
import type { User } from "@/oidc";
import { useAddressDataStore } from "@/store/process/steps/addressData";
import { useImportantQuestionsStore } from "@/store/process/steps/importantQuestions";

export type GenderType = "MALE" | "FEMALE";
export type RelationshipType = 50 | 53 | 51; // 50 = marriage / 53 = registered partnership / 51 = other

export interface DoubleClientData {
  legalStatus: RelationshipType | null;
}

export interface PersonalData {
  gender: GenderType | null;
  firstName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  phoneNumber: string | null;
  email: string | null;
  nationality: string | null;
  hasNotSecondNationality: boolean | null;
  nationality2?: string | null;
  inEducation?: boolean;
  [key: string]: unknown;
}

export interface FileMap {
  name: string;
  data: File;
}

const hasCompletedBasicPersonalDataCollection = (data: PersonalData) => {
  return (
    !!data.dateOfBirth &&
    !!data.firstName &&
    !!data.lastName &&
    !!data.gender &&
    !!data.nationality &&
    (data.hasNotSecondNationality ? true : !!data.nationality2) &&
    !!validateMinAge(new Date(data.dateOfBirth), 18) &&
    !!validateMaxAge(new Date(data.dateOfBirth), 120)
  );
};

const hasCompletedExtendedPersonalDataCollection = (data: PersonalData) => {
  return !!data.phoneNumber && !!data.email;
};

const createEmptyPersonalData = (): PersonalData => ({
  gender: null,
  firstName: null,
  lastName: null,
  dateOfBirth: null,
  phoneNumber: null,
  email: null,
  nationality: null,
  hasNotSecondNationality: true,
  nationality2: null,
});

export const usePersonalDataStore = defineStore(
  "personalData",
  () => {
    const finished = ref(false);
    const finishedExtended = ref(false);
    const leadClientIndex = ref(0);
    const doubleClientData = ref<DoubleClientData>({
      legalStatus: null,
    });
    const dataSets = ref<PersonalData[]>([createEmptyPersonalData()]);
    const educationProofFiles = ref<FileMap[]>([]);
    const selectedEducationTerms = ref(false);

    function $reset() {
      finished.value = false;
      finishedExtended.value = false;
      leadClientIndex.value = 0;
      doubleClientData.value = {
        legalStatus: null,
      };
      dataSets.value = [createEmptyPersonalData()];
      educationProofFiles.value = [];
      selectedEducationTerms.value = false;
    }

    const setFinished = () => {
      finished.value = true;
    };

    const setDataFromUserProfile = (profile: User) => {
      const dataSet = dataSets.value[0];
      assertIsDefined(dataSet, "dataSet");
      const newData: Partial<PersonalData> = {};

      if (!dataSet.gender && profile.wwwliipSalutation) {
        const tryGetSalutation = (liipSalutation: string) => {
          const sal = liipSalutation.toLowerCase();
          if (sal === "herr") {
            return "MALE";
          }
          if (sal === "frau") {
            return "FEMALE";
          }
          return null;
        };
        newData.gender = tryGetSalutation(profile.wwwliipSalutation);
      }
      if (!dataSet.firstName && profile.given_name) {
        newData.firstName = profile.given_name;
      }
      if (!dataSet.lastName && profile.family_name) {
        newData.lastName = profile.family_name;
      }
      if (!dataSet.dateOfBirth && profile.wwwliipBirthdate) {
        newData.dateOfBirth = moment(profile.wwwliipBirthdate, "DD.MM.YYYY").format("YYYY-MM-DD");
      }
      if (!dataSet.email) {
        newData.email = profile.email;
      }
      if (!dataSet.phoneNumber && profile.wwwliipPhone) {
        newData.phoneNumber = profile.wwwliipPhone;
      }

      updateDataSetForIndex({ index: 0, data: newData });
    };

    const completedDataCollection = computed(() => {
      if (!dataSets.value[0]) return [];

      const completionState = [true];

      const dataSetFirstUser = { ...dataSets.value[0] };

      delete dataSetFirstUser.legalStatus;
      if (dataSetFirstUser.hasNotSecondNationality !== null && dataSetFirstUser.hasNotSecondNationality) {
        delete dataSetFirstUser.nationality2;
      }
      completionState[0] = hasCompletedBasicPersonalDataCollection(dataSetFirstUser);

      if (dataSets.value[1]) {
        const dataSet2ndUser = { ...dataSets.value[1] };
        if (dataSet2ndUser.hasNotSecondNationality !== null && dataSet2ndUser.hasNotSecondNationality) {
          delete dataSet2ndUser.nationality2;
        }
        completionState[1] = hasCompletedBasicPersonalDataCollection(dataSet2ndUser);
      }

      return completionState;
    });

    const completedExtendedDataCollection = computed(() => {
      if (!dataSets.value[0]) return [];

      const completionState = [true];

      const dataSetFirstUser = { ...dataSets.value[0] };

      delete dataSetFirstUser.legalStatus;
      if (dataSetFirstUser.hasNotSecondNationality !== null && dataSetFirstUser.hasNotSecondNationality) {
        delete dataSetFirstUser.nationality2;
      }
      completionState[0] = hasCompletedExtendedPersonalDataCollection(dataSetFirstUser);

      if (dataSets.value[1]) {
        const dataSet2ndUser = { ...dataSets.value[1] };
        if (dataSet2ndUser.hasNotSecondNationality !== null && dataSet2ndUser.hasNotSecondNationality) {
          delete dataSet2ndUser.nationality2;
        }
        completionState[1] = hasCompletedExtendedPersonalDataCollection(dataSet2ndUser);
      }

      return completionState;
    });

    const completedLeadClientDataCollection = computed(() => {
      return leadClientIndex.value !== null;
    });

    const completedEducationProofDataCollection = computed(() => {
      return educationProofFiles.value.length > 0;
    });

    const isDoubleClient = computed(() => {
      return dataSets.value.length > 1;
    });

    const birthDateOfOlderClient = computed(() => {
      return dataSets.value
        .map((clientData) => clientData.dateOfBirth)
        .sort()
        .shift();
    });

    const isEligibleForEducationTerms = computed<boolean>(() => {
      if (isDoubleClient.value) return false;
      if (birthDateOfOlderClient.value === null || birthDateOfOlderClient.value === undefined) return false;
      const ageOffset = calculateDateOffsetInYears(
        birthDateOfOlderClient.value as unknown as Date,
        Date() as unknown as Date,
      );

      return ageOffset < 29;
    });

    const updateDataSetForIndex = (payload: { index: number; data: Partial<PersonalData> }) => {
      const dataSetsClone = [...dataSets.value];
      const item = dataSetsClone[payload.index];
      assertIsDefined(item, "item");
      dataSetsClone[payload.index] = { ...item, ...payload.data };
      dataSets.value = dataSetsClone;
      if (payload.data.dateOfBirth) {
        resetEducationData();
      }
    };

    const updateDoubleClientData = (payload: { data: Partial<DoubleClientData> }) => {
      const doubleClientDataClone = {
        ...doubleClientData.value,
        ...payload.data,
      };
      doubleClientData.value = doubleClientDataClone;
    };

    const addSecondClient = () => {
      if (dataSets.value.length === 2) return;
      assertIsDefined(dataSets.value[0], "dataSets.value[0]");
      dataSets.value = [{ ...dataSets.value[0] }, createEmptyPersonalData()];
      useAddressDataStore().addSecondClient();
      useImportantQuestionsStore().setDoubleClient();
    };

    const removeSecondClient = () => {
      if (dataSets.value.length === 1) return;
      assertIsDefined(dataSets.value[0], "dataSets.value[0]");
      dataSets.value = [{ ...dataSets.value[0] }];
      useAddressDataStore().removeSecondClient();
    };

    const setFinishedExtended = () => {
      finishedExtended.value = true;
    };

    const setSelectedEducationTerms = (selectEducationTerms: boolean) => {
      selectedEducationTerms.value = selectEducationTerms;
      assertIsDefined(dataSets.value[0], "dataSets.value[0]");
      dataSets.value[0].inEducation = selectEducationTerms;
    };

    const addEducationProofFile = (file: FileMap) => {
      educationProofFiles.value = [...educationProofFiles.value, file];
    };

    const removeEducationProofFile = (index: number) => {
      educationProofFiles.value = educationProofFiles.value.filter((_, i) => i !== index);
    };

    const resetEducationData = () => {
      educationProofFiles.value = [];
      selectedEducationTerms.value = false;
      assertIsDefined(dataSets.value[0], "dataSets.value[0]");
      dataSets.value[0].inEducation = false;
    };

    return {
      $reset,
      finished,
      setFinished,
      finishedExtended,
      leadClientIndex,
      doubleClientData,
      dataSets,
      educationProofFiles,
      selectedEducationTerms,
      setDataFromUserProfile,
      completedDataCollection,
      completedExtendedDataCollection,
      completedLeadClientDataCollection,
      completedEducationProofDataCollection,
      isDoubleClient,
      birthDateOfOlderClient,
      isEligibleForEducationTerms,
      updateDataSetForIndex,
      updateDoubleClientData,
      addSecondClient,
      removeSecondClient,
      setFinishedExtended,
      setSelectedEducationTerms,
      addEducationProofFile,
      removeEducationProofFile,
      resetEducationData,
    };
  },
  {
    persist: true,
  },
);
