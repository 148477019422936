import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { gettext } from "@/core/translations";
import { assertIsDefined } from "@/helpers/types";

import { usePersonalDataStore } from "./personalData";

export type Order = {
  id: number;
  sku: string;
  name: string;
  benefits: Benefit[];
};

export type Benefit = {
  id: number;
  sku: string;
  name: string;
  mandatory: boolean;
  clientIndex?: number;
  disabled?: boolean;
};

export type OrderWithGroupedBenefits = (Order & { groupedBenefits: Partial<Record<string, Benefit[]>> })[];

const { $gettext } = gettext;

export const useOrderStore = defineStore(
  "order",
  () => {
    const finished = ref(false);
    const orders = ref<Order[]>([]);

    const ordersWithGroupedBenefits = computed(() =>
      orders.value.map((o) => ({
        ...o,
        groupedBenefits: Object.groupBy(o.benefits, (b) => b.sku),
      })),
    );

    const personalDataStore = usePersonalDataStore();

    const setFinished = () => {
      finished.value = true;
    };

    const unsetFinished = () => {
      finished.value = false;
    };

    const assignBenefitToClient = (productId: number, benefitId: number, clientIndex: number) => {
      const benefit = orders.value.find((o) => o.id === productId)?.benefits.find((b) => b.id === benefitId);
      if (!benefit) {
        throw new Error($gettext("Zuweisung fehlgeschlagen"));
      }
      benefit.clientIndex = clientIndex;
    };

    /**
     * automatically assigns benefits to clients
     * returns false if not all benefits could be automatically assigned.
     * used for routing guard of benefit assignment
     */
    const tryAutomaticAssignment = (): boolean => {
      let successfullyAssigned = true;
      const isDoubleClient = personalDataStore.isDoubleClient;
      orders.value.forEach((o) => {
        const groups = Object.groupBy(o.benefits, (i) => i.sku);
        Object.values(groups).forEach((g) => {
          if (!g) {
            return;
          }
          if (!isDoubleClient) {
            g.forEach((b) => (b.clientIndex = 0));
          } else if (isDoubleClient && g.length === 2) {
            assertIsDefined(g[0], "benefit 0");
            assertIsDefined(g[1], "benefit 1");
            g[0].clientIndex = 0;
            g[1].clientIndex = 1;
          } else {
            successfullyAssigned = false;
          }
        });
      });

      return successfullyAssigned;
    };

    const assignedAllBenefits = computed(() => {
      return orders.value.every((o) => o.benefits.every((b) => b.clientIndex !== undefined));
    });

    const $reset = () => {
      orders.value = [];
      finished.value = false;
    };

    return {
      finished,
      orders,
      ordersWithGroupedBenefits,
      setFinished,
      unsetFinished,
      assignBenefitToClient,
      assignedAllBenefits,
      tryAutomaticAssignment,
      $reset,
    };
  },
  {
    persist: {
      debug: true,
    },
  },
);
