import "@acrevis/components/style.css";
import "./styles/bootstrap-custom.scss";

import { createGtm } from "@gtm-support/vue-gtm";
import { init as sentryInit } from "@sentry/vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createApp } from "vue";
import { useSnackbar } from "vue-haystack";

import { apiClients } from "@/api/index";
import { baseConfig, gtmConfig, i18nConfig, oidcConfig, sentryConfig, servicesConfig } from "@/config";
import { gettext } from "@/core/translations/index";
import { assertIsDefined } from "@/helpers/types";
import oidcAuth from "@/oidc";
import { useLogger } from "@/services";

import App from "./App.vue";
import BaseSnack from "./components/snacks/BaseSnack.vue";
import router from "./router";

const logger = useLogger();

logger.debug("The evaluated baseConfig", baseConfig);
logger.debug("The evaluated oidcConfig", oidcConfig);
logger.debug("The evaluated i18nConfig", i18nConfig);
logger.debug("The evaluated servicesConfig", servicesConfig);
logger.debug("The evaluated sentryConfig", sentryConfig);
logger.debug("The evaluated gtmConfig", gtmConfig);

void oidcAuth.startup().then((ok) => {
  if (!ok) {
    console.error("Failed to initialize oidc.");
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const app = createApp(App);
  const pinia = createPinia();
  pinia.use(piniaPluginPersistedstate);
  app.use(pinia);

  app.provide("api", apiClients);
  oidcAuth.useRouter(router);
  app.use(router);
  app.use(gettext);

  if (sentryConfig.enabled) {
    logger.debug("Sentry is tracking...");
    sentryInit({
      enabled: sentryConfig.enabled,
      dsn: sentryConfig.dsn,
      environment: sentryConfig.env,
      release: `${sentryConfig.projectName}/${sentryConfig.version ?? ""}`,
    });
  } else {
    logger.debug("Sentry is NOT tracking...");
  }

  if (gtmConfig.enabled) {
    assertIsDefined(gtmConfig.containerId, "gtmConfig.containerId");
    logger.debug("GTM is tracking...");
    app.use(
      createGtm({
        id: gtmConfig.containerId,
        defer: false,
        compatibility: false,
        enabled: gtmConfig.enabled,
        debug: gtmConfig.debug,
        loadScript: true,
        vueRouter: router,
        trackOnNextTick: false,
      }),
    );
  } else {
    logger.debug("GTM is NOT tracking...");
  }

  function handleError(error: unknown) {
    const snackbarStore = useSnackbar();
    snackbarStore.push(BaseSnack, {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      message: gettext.$gettext("Unerwartetet Fehler: %{error}", { error: `${error}` }),
      type: "ERROR",
    });
    throw error;
  }

  // Default Error Handler
  app.config.errorHandler = (error) => handleError(error);
  // Handle Errors outside Vue
  window.addEventListener("error", (error) => handleError(error));
  // Handle errors in Promises that were not caught (for example async watchers)
  window.addEventListener("unhandledrejection", (error) => handleError(error));

  app.mount("#app");
});
