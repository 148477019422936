import type { ZipCityRead } from "@acrevis/integration-api-masterdata-core";
import { pick } from "lodash";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { zipCodeLocationIntegrationServiceApiClient } from "@/api/services/integration";
import { assertIsDefined } from "@/helpers/types";
import type { User } from "@/oidc";

export interface AddressData {
  street: string | null;
  streetNumber: string | null;
  location: ZipCityRead | null;
  hasSameAddress: boolean | null;
}

const createEmptyAddressData = (): AddressData => ({
  street: null,
  streetNumber: null,
  location: null,
  hasSameAddress: false,
});

export const useAddressDataStore = defineStore(
  "addressData",
  () => {
    const finished = ref(false);
    const dataSets = ref<AddressData[]>([createEmptyAddressData()]);

    const $reset = () => {
      dataSets.value = [createEmptyAddressData()];
      finished.value = false;
    };

    const setDataFromUserProfile = async (profile: User) => {
      const dataSet = dataSets.value[0];
      assertIsDefined(dataSet, "dataSet");
      const newData: Partial<AddressData> = {};

      if (!dataSet.location && profile.wwwliipZipcode) {
        try {
          const data = await zipCodeLocationIntegrationServiceApiClient.getZipCities({ zip: profile.wwwliipZipcode });
          if (data.length > 0) {
            newData.location = data[0];
          }
        } catch {
          console.warn("ZIP request failed");
        }
      }

      const splitStreet = (profile.wwwliipStreet || "").split(" ");
      const location = {
        street: profile.wwwliipStreet,
        streetNumber: null as string | null,
      };
      if (splitStreet.length > 0) {
        const lastPart = splitStreet[splitStreet.length - 1];
        const streetNumber = parseInt(lastPart ?? "");
        if (!isNaN(streetNumber)) {
          location.street = splitStreet.slice(0, splitStreet.length - 1).join(" ");
          location.streetNumber = lastPart ?? null;
        }
      }

      if (!dataSet.street && !dataSet.streetNumber) {
        newData.street = location.street;
        newData.streetNumber = location.streetNumber;
      }

      updateDataSetForIndex({ index: 0, data: newData });
    };

    const completedDataCollection = computed(() => {
      if (!dataSets.value[0]) {
        return [];
      }

      const requiredFieldKeys = ["street", "location"];

      const completionState = [true];

      const requiredDataSetFirstUser = pick(dataSets.value[0], requiredFieldKeys);

      completionState[0] = Object.values(requiredDataSetFirstUser).every((value) => value !== null && value !== "");

      if (dataSets.value[1]) {
        const dataSetSecondUser = { ...dataSets.value[1] };
        const requiredDataSetSecondUser = pick(dataSets.value[1], requiredFieldKeys);

        if (dataSetSecondUser.hasSameAddress) {
          completionState[1] = dataSetSecondUser.hasSameAddress;
        } else {
          completionState[1] = Object.values(requiredDataSetSecondUser).every(
            (value) => value !== null && value !== "",
          );
        }
      }

      return completionState;
    });

    const updateDataSetForIndex = (payload: { index: number; data: Partial<AddressData> }) => {
      const dataSetsClone = [...dataSets.value];
      const item = dataSetsClone[payload.index];
      assertIsDefined(item, "item");
      dataSetsClone[payload.index] = { ...item, ...payload.data };
      dataSets.value = dataSetsClone;
    };

    const addSecondClient = () => {
      if (dataSets.value.length >= 2) {
        return;
      }
      dataSets.value.push(createEmptyAddressData());
    };

    const removeSecondClient = () => {
      if (dataSets.value.length === 1 || !dataSets.value[0]) {
        return;
      }
      dataSets.value = [{ ...dataSets.value[0] }];
    };

    const setFinished = () => {
      finished.value = true;
    };

    return {
      $reset,
      finished,
      dataSets,
      setDataFromUserProfile,
      completedDataCollection,
      addSecondClient,
      removeSecondClient,
      setFinished,
      updateDataSetForIndex,
    };
  },
  {
    persist: true,
  },
);
