<template>
  <component :is="iconSvg" :key="icon" :class="$style.icon" :style="css"></component>
</template>

<script setup lang="ts">
import type { FunctionalComponent, SVGAttributes } from "vue";
import { computed } from "vue";

import { assertIsDefined } from "@/helpers/types";

const iconModules: Record<string, FunctionalComponent<SVGAttributes>> = import.meta.glob<FunctionalComponent>(
  "../../node_modules/@acrevis/icons/svg/**/*.svg",
  {
    eager: true,
    query: "?component",
  },
);

const iconMap = Object.entries(iconModules).reduce(
  (prev: Record<string, FunctionalComponent<SVGAttributes>>, icon: [string, FunctionalComponent<SVGAttributes>]) => {
    const [iconPath, iconModule] = icon;
    const iconName = iconPath.split("/");

    const iconNameWithoutExtension = iconName[iconName.length - 1]?.split(".svg")[0];
    assertIsDefined(iconNameWithoutExtension, "iconNameWithoutExtension");

    return {
      ...prev,
      [`${iconName[iconName.length - 2]}/${iconNameWithoutExtension}`]: iconModule,
    };
  },
  {},
);

const props = withDefaults(
  defineProps<{
    size?: string;
    icon: string;
  }>(),
  {
    size: "24px",
  },
);

const css = computed(() => ({
  "--size": props.size,
}));

const iconSvg = computed((): FunctionalComponent<SVGAttributes> | null => {
  if (props.icon === null) {
    return null;
  }
  try {
    return iconMap[props.icon] ?? null;
  } catch (e) {
    console.warn("Icon not found: " + props.icon);
    return iconMap["navigation/error"] ?? null;
  }
});
</script>

<style lang="scss" module>
.icon {
  width: var(--size) !important;
  height: var(--size) !important;
  display: inline-block;
}
a:hover > .icon path {
  color: $red-one;
}
</style>
